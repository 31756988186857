<template>
  <div>
    <!-- 分销管理 -->
    <en-table-layout tips :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!-- 活动分类 -->
          <div class="conditions">
            <div>活动分类：</div>
            <el-cascader v-model="value" :options="categoryList" :props="{ checkStrictly: true }" expand-trigger="hover" style="width:69%;"
              @change="handleChange"
              clearable
            >
            </el-cascader>
          </div>
          <!--申请时间-->
          <div class="conditions">
            <div>活动时间：</div>
            <!-- type="date" -->
            <el-date-picker
              v-model="advancedForm.times"
              :editable="false"
              style="width:260px"
              value-format="timestamp"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
            <!--:picker-options="{disabledDate: MixinDisabledDate}" :default-time="['00:00:00', '23:59:59']" -->
          </div>
          <!--关键字-->
          <div class="conditions">
            <div>关键字选择：</div>
            <el-select
              class="ipt-default"
              style="width:150px"
              v-model="advancedForm.keyWord"
              clearable
            >
              <el-option value="活动名称" label="根据活动名称"></el-option>
              <el-option value="活动编号" label="根据活动编号"></el-option>
              <el-option value="商户名称" label="根据商户名称"></el-option>
              <!-- <el-option value="goods_name" label="根据活动名称"></el-option>
              <el-option value="goods_sn" label="根据活动编号"></el-option>
              <el-option value="seller_name" label="根据客户名称"></el-option> -->
            </el-select>
            <el-input
              class="ipt-default"
              style="width: 150px"
              v-model="advancedForm.keyValue"
              placeholder="请输入关键字进行搜索"
              clearable
            ></el-input>
            <!-- <el-input
              class="ipt-default"
              style="width: 150px"
              v-model="advancedForm.keyword"
              placeholder="请输入关键字进行搜索"
              clearable
            ></el-input> -->
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格上部按钮 -->
      <div slot="toolbar">
        <el-button class="btn-default" @click="handleShowActivity">添加分销活动</el-button>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="goods_name" label="活动名称" width="225">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.thumbnail" />
              <div :title="scope.row.goods_name">{{ scope.row.goods_name}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shop_name" label="所属店铺名称" width="180" />
        <el-table-column prop="sn" label="活动编号" width="180" />
        <el-table-column prop="category_first" label="活动一级分类" width="180" />
        <el-table-column prop="category_second" label="活动二级分类" width="180">
          <template slot-scope="scope">{{ scope.row.category_second?scope.row.category_second:'——'}}</template>
        </el-table-column>
        <el-table-column label="原价" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.mkt_low===scope.row.mkt_high?scope.row.mkt_low+"元":scope.row.mkt_low+"元起"}}</template>
        </el-table-column>
        <el-table-column label="销售价" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.price_low===scope.row.price_top?scope.row.price_low+"元":scope.row.price_low+"元起"}}</template>
        </el-table-column>
        <el-table-column label="佣金" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.agent_low!==scope.row.agent_high?(scope.row.agent_low+'~'+scope.row.agent_high+"元"):scope.row.agent_low+"元"}}</template>
        </el-table-column>
        <el-table-column prop="buy_count" label="累计销量" width="180" />
        <el-table-column prop="sale_limit_num" label="名额限制" width="180" />
        <el-table-column prop="quantity" label="剩余名额" width="180" />
        <el-table-column label="活动开始日期" width="180">
          <template slot-scope="scope">{{ scope.row.min_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="活动结束日期" width="180">
          <template slot-scope="scope">{{ scope.row.max_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <!--添加活动 dialog-->
    <el-dialog
      title="添加活动"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <en-table-layout
        tips
        :toolbar="false"
        @selection-change="handleSelectionChange"
        :tableData="activityTableData.data"
        :loading="loading"
        :selectRow="GoodIds_list"
        ref="multipleTable"
        :rowKey="(row)=>{return row.goods_id}"
      >
        <!-- 搜索条件 -->
        <div slot="tips" class="inner-toolbar">
          <div class="toolbar-btns">
            <!--关键字-->
            <div class="conditions">
              <div>关键字选择：</div>
              <el-select
                class="ipt-default"
                style="width:140px"
                v-model="activityadvancedForm.keyword"
                clearable
              >
                <el-option value="活动名称" label="根据活动名称"></el-option>
                <el-option value="活动编号" label="根据活动编号"></el-option>
                <el-option value="所属客户名称" label="根据客户名称"></el-option>
              </el-select>
              <el-input
                class="ipt-default"
                style="width: 160px"
                v-model="activityadvancedForm.keyvalue"
                placeholder="请输入关键字进行搜索"
                clearable
              ></el-input>
              <div>活动分类：</div>
              <el-cascader
                style="width: 150px"
                v-model="value1"
                clearable
                :options="categoryList"
                :props="{ checkStrictly: true }"
                @change="handleChange"></el-cascader>
              <div>活动日期：</div>
              <el-date-picker
                style="width: 260px"
                v-model="advancedForm.times"
                value-format="timestamp"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <el-button class="btn-default" @click="searchEventActivity">筛选</el-button>
            </div>
          </div>
        </div>

        <!-- 表格数据 -->
        <template slot="table-columns">
          <el-table-column type="selection" :reserve-selection="true" width="55"></el-table-column>
          <el-table-column prop="goods_name" label="活动名称" width="225">
            <template slot-scope="scope">
              <div class="imgBox">
                <img :src="scope.row.thumbnail" />
                <div :title="scope.row.goods_name">{{ scope.row.goods_name}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="seller_name" label="所属店铺名称" width="180" />
          <el-table-column prop="sn" label="活动编号" width="180" />
          <el-table-column prop="category_first" label="活动一级分类" width="180" />
          <el-table-column prop="category_second" label="活动二级分类" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.category_second?scope.row.category_second:'——'}}</template>
          </el-table-column>
          <el-table-column label="原价" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.mkt_low?scope.row.mkt_low===scope.row.mkt_high?scope.row.mkt_low+'元':scope.row.mkt_low+'元起':'——' }}</template>
          </el-table-column>
          <el-table-column prop="price_low" label="销售价" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.price_low===scope.row.price_top? scope.row.price_low+'元':scope.row.price_low+'元起'}}</template>
          </el-table-column>
          <!-- <el-table-column prop="price_low" label="团购价" width="180" /> -->
          <el-table-column label="累计销量" width="180">
            <template slot-scope="scope">{{ scope.row.buy_count?scope.row.buy_count:'——'}}</template>
          </el-table-column>
          <el-table-column label="名额限制" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.sale_limit_num?scope.row.sale_limit_num:'——' }}</template>
          </el-table-column>
          <el-table-column prop="quantity" label="剩余名额" width="180">
            <template slot-scope="scope">{{ scope.row.quantity?scope.row.quantity:'——' }}</template>
          </el-table-column>
          <el-table-column label="申请上架时间" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.apply_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
          </el-table-column>
          <el-table-column label="审核时间" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.auth_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
          </el-table-column>
          <el-table-column label="活动开始日期" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.min_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
          </el-table-column>
          <el-table-column label="活动结束日期" width="180">
            <template
              slot-scope="scope"
            >{{ scope.row.max_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
          </el-table-column>
          <el-table-column label="活动链接地址" width="180">
            <template slot-scope="scope">{{ scope.row.act_url }}</template>
          </el-table-column>
        </template>
        <!-- 表格分页 -->
        <el-pagination
          v-if="activityTableData"
          slot="pagination"
          @size-change="handlePageSizeChangeactivity"
          @current-change="handlePageCurrentChangeactivity"
          :current-page="activityTableData.page_no"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="activityTableData.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="activityTableData.data_total"
        ></el-pagination>
      </en-table-layout>

      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="handleAddActivity()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Distribution from "@/api/distribution";
import * as API_Category from '@/api/category'
// import { RegExp } from "bms-common/ui-utils";

export default {
  name: "salesActivityList",

  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      value: [],
      value1: [],
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },
      /** 列表数据 */
      tableData: "",
      /** 高级搜索数据 */
      advancedForm: {
        times: "", // 时间
        category_id: "", // 商品一级分类
        category_id2: "", // 二级分类
        keyWord: "", // 根据关键字
        keyValue: "", // 根据关键字搜索
      },
      /** 列表activity参数 */
      activityparams: {
        page_no: 1,
        page_size: 10,
        market_enable: 1
      },
      /** 高级搜索数据 */
      activityadvancedForm: {
        // keyword_type: "", // 根据关键字
        keyword: "", // 根据关键字搜索
        keyvalue: "",
      },
      /** 添加分销活动 */
      dialogVisible: false,
      /**  activity列表数据 */
      activityTableData: [],

      ids_list: [], // 复选框数据
      /**  所有分销商品ID */
      GoodIds_list: [],

      /** 分类列表 */
      categoryList: []
    };
  },
  watch: {
    // "addMemberForm.region"(next, prev) {
    //   if (next && next.length) {
    //     this.$refs.addMemberForm.clearValidate("region");
    //   }
    // }
  },
  mounted() {
    this.GET_List();
    API_Category.getCategoryChildren().then(res => {
      res.forEach(item => {
        const obj = {}
        obj['value'] = item.category_id
        obj['label'] = item.name
        obj['children'] = []
        if (item['children']) {
          item['children'].forEach(item_children => {
            const obj1 = {}
            obj1['value'] = item_children.category_id
            obj1['label'] = item_children.name
            obj['children'].push(obj1)
          })
        }
        this.categoryList.push(obj)
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.GET_List();
    next();
  },
  methods: {
    handleChange(value) {
      if (value.length !== 0) {
        this.advancedForm.category_id = value[0]
        if (value.length === 2) {
          this.advancedForm.category_id2 = value[1]
        }
      } else {
        this.advancedForm.category_id2 = ''
        this.advancedForm.category_id = ''
      }
    },
    /** 添加活动时 */
    handleAddActivity() {
      console.log(this.ids_list);
      API_Distribution.addGoodsActivity(this.ids_list).then((res) => {
        this.$message.success("添加成功！");
        this.dialogVisible = false;
        this.GET_List();
      });
    },
    /** 当选择项发生变化 */
    handleSelectionChange(val) {
      this.ids_list = val.map((item) => item.goods_id);
    },
    /** 添加分销活动 */
    handleShowActivity() {
      // 先获取所有分销商品ID
      API_Distribution.getGoodIds().then((res) => {
        this.GoodIds_list = res.map((item) => item.goods_id);
      });
      this.getGoodsActivity();
    },
    /** 获取所有活动列表 */
    getGoodsActivity() {
      API_Distribution.getGoodsActivity(this.activityparams).then((res) => {
        this.activityTableData = res;
        this.dialogVisible = true;
        // 选中数据回显
        this.$nextTick(() => {
          this.$refs.multipleTable.selectCheck(this.GoodIds_list, "goods_id");
        });
      });
    },

    /** 搜索事件触发 */
    searchEventActivity() {
      this.activityparams.page_no = 1;
      this.activityparams = {
        ...this.activityparams,
        page_no: 1,
        market_enable: 1,
        ...this.activityadvancedForm,
      };
      delete this.activityparams.category_first
      delete this.activityparams.category_second
      delete this.activityparams.active_start
      delete this.activityparams.active_end
      if (this.value1.length !== 0) {
        if (this.value1.length === 1) {
          this.activityparams.category_first = this.value1[0]
        }
        this.activityparams.category_first = this.value1[0]
        this.activityparams.category_second = this.value1[1]
      }
      if (this.advancedForm.times) {
        this.activityparams.active_start = this.advancedForm.times[0] / 1000;
        this.activityparams.active_end = this.advancedForm.times[1] / 1000;
      }
      console.log(this.activityparams, this.activityadvancedForm);
      this.getGoodsActivity();
    },
    /** 分页大小发生改变 */
    handlePageSizeChangeactivity(size) {
      this.activityparams.page_size = size;
      this.getGoodsActivity();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChangeactivity(page) {
      this.activityparams.page_no = page;
      this.getGoodsActivity();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 删除该活动 */
    handleDelete(row) {
      this.$confirm("确认要删除该活动吗？", "提示", {
        type: "warning",
        cancelButtonClass: "btn-default-dialog-no",
        confirmButtonClass: "btn-default-dialog-yes",
      })
        .then(() => {
          API_Distribution.deteleDistributionPage(row.id).then((res) => {
            this.$message.success("删除成功！");
            this.GET_List();
          });
        })
        .catch(() => {});
    },
    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      this.params = {
        ...this.params,
        page_no: 1,
        ...this.advancedForm,
      };
      delete this.params["goods_name"];
      delete this.params["goods_sn"];
      delete this.params["seller_name"];
      delete this.params.active_start;
      delete this.params.active_end;
      if (this.advancedForm.times) {
        this.params.active_start = this.advancedForm.times[0] / 1000;
        this.params.active_end = this.advancedForm.times[1] / 1000;
      }
      delete this.params.times;
      // this.params[this.advancedForm.keyword_type] = this.advancedForm.keyword;
      this.params.shop_status = this.advancedForm.shop_status;
      this.GET_List();
    },

    /** 获取会员列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Distribution.getDistributionPage(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
::v-deep .el-dialog {
  width: 80%;
}
.imgBox {
  display: flex;
  align-items: center;
  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }
  div {
    width: 150px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    flex: 1;
    text-align: left;
  }
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}

div.toolbar-btns .conditions {
  margin-right: 0px;
}
</style>
